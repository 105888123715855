export default {
    nav: {
        home: 'Главная',
        login: 'Войти',
        register: 'Зарегистрироваться',
        playnow: 'Играть сейчас',
        logout: 'Выйти',
        profile: 'Профиль'
    },
    loginPage: {
        title: "Вход в аккаунт",
        useAcc: 'Для входа используйте свой аккаунт WarForGalaxy.com',
        signIn: 'Войти',
        rememberMe: "Запомнить меня",
        isForget: "Забыли пароль?",
        recover: "Восстановить аккаунт",
        isNotRegister: "Еще не зарегистрировались?",
        createAcc: "Создать аккаунт",
        signSoc: "Войдите с помощью социальной сети",
        fb: "Facebook",
        google: "Google",
        vk: "Вконтакте",
        email: "Email",
        pass: "Пароль"
    },
    registerPage: {
        title: "Зарегистрируйся и играй",
        agreeRules: "Соглашаюсь с правилами",
        agreePromo: "Хочу получать сообщения",
        continue: "Продолжить",
        email: "Email",
        pass: "Пароль",
        nick: "Выберите имя в игре",
        rptPass: "Повторите пароль"
    },
    recoveryPage: {
        title: "Восстановление аккаунта",
        submitbtn: "Восстановить"
    },
    updatePassPage: {
        title: "Изменение пароль",
        pass: "Новый пароль",
        rptPass: "Повторите новый пароль",
        submitbtn: "Сохранить"
    },
    profilePage: {
        user: "Пользователь",
        credites: "Кредиты",
        pcs: "ед."
    },
    socialCompletePage: {
        title: "Завершение регистрации",
        submitbtn: "Завершить"
    }
}
