<template>
  <div class="row center-xs middle-xs recovery-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <!-- <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div> -->
      <h1>{{ $t("profilePage.user") }} &laquo;{{ username }}&raquo;</h1>
      <h2>{{ $t("profilePage.credites") }} {{ credites }} {{ $t("profilePage.pcs") }} <a href="/">(Пополнить)</a></h2>
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <h3 class="block-title">Изменение пароля</h3>
          <form @submit.prevent="submitHandlerPassword">

            <input class="input" type="password" name="password" v-model="password" :placeholder="this.$t('updatePassPage.pass')" minlength="8" required>
            <input class="input" type="password" name="confirm_password" v-model="confirmPassword" :placeholder="this.$t('updatePassPage.rptPass')" minlength="8" required>
            <div class="row success-msg" v-if="successResultPassword">
              <div class="col-xs-12">
                <p>Ваш пароль успешно изменен.</p>
              </div>
            </div>
            <div class="row error-msg" v-if="errorPassword">
              <div class="col-xs-12">
                <p>{{ errorPassword }}</p>
              </div>
            </div>
            <button class="btn" type="submit">{{ $t("updatePassPage.submitbtn") }}</button>
          </form>
        </div>
        <div class="col-xs-12 col-md-6 text-left">
          <h3 class="block-title">Изменение почты</h3>
          <form @submit.prevent="submitHandlerEmail">
            <input class="input" type="email" name="email" :value="email" required>
            <div class="row success-msg" v-if="successResultEmail">
              <div class="col-xs-12">
                <p>Для окончательной привязки нового email к аккаунту вам необходимо пройти по ссылке в письме, которое отправлено на новый email</p>
              </div>
            </div>
            <div class="row error-msg" v-if="errorEmail">
              <div class="col-xs-12">
                <p>{{ errorEmail }}</p>
              </div>
            </div>
            
            <button class="btn" type="submit">{{ $t("updatePassPage.submitbtn") }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changePass, changeEmail } from '../lib/api'
import { fdToObj } from '../lib/utils'

export default {
  name: 'ProfilePage',
  data: () => {
    return {
      errorPassword: '',
      errorEmail: '',
      password: '',
      confirmPassword: '',
      successResultPassword: false,
      successResultEmail: false
    }
  },
  computed: {
    username() {
      return this.$store.state.user.name
    },
    email() {
      return this.$store.state.user.email
    },
    credites() {
      return this.$store.state.user.credites
    },
    userToken() {
      return this.$store.state.user.token
    }
  },
  methods: {
    checkPasswords() {
      if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
        return true
      }
      
      return false
    },
    clearAfter(v, newv = '', ms = 1500) {
      setTimeout(() => {
        this[v] = newv
      }, ms)
    },
    async submitHandlerPassword(ev) {
      this.errorPassword = ''
      const form = ev.target

      if (!this.checkPasswords()) {
        this.errorPassword = "Пароли не совпадают"
        this.clearAfter('errorPassword', '', 3000)
        return false
      }

      const formObj = fdToObj(new FormData(form))

      formObj['token'] = this.userToken

      const { data } = await changePass(formObj)
      if (data.status !== 1) {
        this.setErrorMsg(data.errorid, 'errorPassword')
        return;
      }

      form.reset()

      this.successResultPassword = true
      this.clearAfter('successResultPassword', false, 3000)
    },
    async submitHandlerEmail(ev) {
      this.errorEmail = ''
      const form = ev.target

      // TODO: checking that new email is not equal the old email

      const formObj = fdToObj(new FormData(form))
      formObj['token'] = this.userToken

      const { data } = await changeEmail(formObj)
      if (data.status !== 1) {
        this.setErrorMsg(data.errorid, 'errorEmail')
        return;
      }

      form.reset()

      this.successResultEmail = true
      this.clearAfter('successResultEmail', false, 5000)
    },
    setErrorMsg(errorid, target) {
      let errorMsg = ''

      switch (errorid) {
        case 4:
          return window.location.href = '/login'
        default:
          errorMsg = "Техническая ошибка. Попробуйте позже"
      }

      this[target] = errorMsg
      this.clearAfter(target, '', 3000)
    }
  }
  // beforeMount() {
  //   if (!this.$store.getters.isAuth) {
  //     return this.$router.push({ path: '/login' })
  //   }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.recovery-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

.success-msg p {
  background: inherit;
  color: #75ce75;
  padding: 0;
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

h2 {
  margin-bottom: 20px;

  a {
    font-weight: normal;
    font-size: 1rem;
  }
}

h3 {
  text-align: left;
  margin-bottom: 10px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  //margin-left: 2px;
  display: block;
}

.soc-btns {
  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}
</style>
