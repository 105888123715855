import md5 from 'blueimp-md5'

export const fdToObj = formData => {
    const obj = {}

    for (let pair of formData.entries()) {
        obj[ pair[0] ] = pair[1]
    }

    return obj
}

export const saveToLS = (name, value) => {
    window.localStorage.setItem(name, value)
}

export const preparePassword = plainPass => md5(plainPass).toUpperCase()

export const classesEndsWith = (el, search) => {
    const classAr = Array.from(el.classList);
    return classAr.filter(item => item.endsWith(search))
}
