<template>
  <div id="app">
    <div id="header">
      <div class="warning-message" v-if="isWithoutEmail">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <p class="text-center">Ваш аккаунт заполнен неполностью. Настоятельно рекомендуем привязать почту, чтобы воспользовать всеми возможностями сайта и игры.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="header-bg">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <a @click.prevent="toggleNav" class="toggler">Главное меню</a>
              <nav :class="{ 'opened': this.isOpenedNav }">
                <router-link to="/">{{ $t('nav.home') }}</router-link>
                <a href="http://forum.warforgalaxy.com/">Форум</a>
                <a href="/play">{{ $t('nav.playnow') }}</a>
                <template v-if="isAuth">
                  <router-link to="/profile">{{ $t('nav.profile') }}</router-link>
                  <router-link to="/logout">{{ $t('nav.logout') }}</router-link>
                </template>
                <template v-else>
                  <router-link to="/login">{{ $t('nav.login') }}</router-link>
                  <router-link to="/register">{{ $t('nav.register') }}</router-link>
                </template>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- header -->

    <div id="main">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss">
@import "./vendor/reset.scss";
@import "./vendor/flexboxgrid.scss";
@import "./vendor/common.scss";
</style>

<script>
import types from './types'

export default {
  name: 'app',
  data() {
    return {
      isOpenedNav: false
    }
  },
  methods: {
    toggleNav() {
      this.isOpenedNav = !this.isOpenedNav
    }
  },
  created() {
    const token = window.localStorage.getItem('glxtoken')
    if (token) {
      this.$store.commit(types.SET_TOKEN, token)
    }
  },
  async mounted() {
    await this.$store.dispatch(types.CHECK_TOKEN)
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth
    },
    isWithoutEmail() {
      return this.$store.getters.isAuth && !this.$store.state.user.email
    },
    username() {
      return this.$store.state.user.name
    }
  }
}
</script>
