export default {
    nav: {
        home: 'Home',
        login: 'Login',
        register: 'Register',
        playnow: 'Play now'
    },
    loginPage: {
        title: "Log in to account",
        useAcc: 'Use your WarForGalaxy.com account',
        signIn: 'Log In',
        rememberMe: "Remember me",
        isForget: "Forgot password?",
        recover: "Recover account",
        isNotRegister: "Have not account?",
        createAcc: "Create account",
        signSoc: "Log in using social networks",
        fb: "Facebook",
        google: "Google",
        vk: "Vkontakte",
        email: "Email",
        pass: "Password"
    },
    registerPage: {
        title: "Register and play",
        agreeRules: "I agree with rules",
        agreePromo: "I agree to receive promo",
        continue: "Continue",
        email: "Email",
        pass: "Password",
        nick: "Enter your nick in the game",
        rptPass: "Confirm password"
    }
}
