<template>
  <div class="home-page">
    <div class="intro">
      <div class="center">
        <div class="logo">
            <img src="~@/assets/intro_logo.png" alt="">
        </div>
        <h1>ВПЕРЁД К ПОБЕДЕ!</h1>
        <h2>Статегическая MMO</h2>
        <button class="btn" @click="gameNow">Играть сейчас</button>
      </div>
      <div class="scroll-down-arrows">
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
      </div>
    </div>

    <div class="video">
      <div class="container">
        <h1>Трейлер игры</h1>
        <iframe 
          src="https://www.youtube.com/embed/v_FP7V3M_cE" 
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen></iframe>
      </div>
    </div>
    
    <div class="ship">
      <div class="container">
        <h1>Вас ждут сражения, исследования, добыча ресурсов, производство и реалистичная экономика</h1>
        <p>
          Вместе с сотнями тысяч других игроков выбирайте профессию и занятия себе по душе: война, пиратство, торговля и исследования - в вашем распоряжении безграничные возможности и звездные системы.
        </p>
      </div>
    </div>

    <div class="intro intro-bottom">
      <div class="center">
        <div class="logo">
            <img src="~@/assets/intro_logo.png" alt="">
        </div>
        <h2>Проложи свой путь к звёздам</h2>
        <button class="btn" @click="gameNow">Играть сейчас</button>
      </div>
    </div>
    <div class="footer-nav">
      <div class="container">
        <nav>
          <a href="#">ЦЕНТР ПОДДЕРЖКИ</a>
          <a href="#">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</a>
          <a href="#">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</a>
        </nav>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .btn {
    text-transform: uppercase;
    margin-top: 5px;
  }

  .logo {
    img {
      width: 393px;
      height: 506px;

      @media (max-width: 575.98px) {
        width: 291px;
        height: 376px;
      }
    }
  }
</style>

<script>
// @ is an alias to /src

export default {
  name: 'home',
  methods: {
    gameNow() {
      window.location.href = '/play'
    }
  }
}
</script>
