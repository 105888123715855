<template>
  <div class="row center-xs middle-xs login-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div>
      <h1>{{ $t("loginPage.title") }}</h1>
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <p class="before end-with-ellipsis">{{ $t("loginPage.useAcc") }}</p>
          <form @submit.prevent="submitHandler">
            <input class="input" type="text" name="login" :placeholder="this.$t('loginPage.email')" autocomplete="off" required>
            <input class="input" type="password" name="password" :placeholder="this.$t('loginPage.pass')" minlength="8" required>
            <div class="row error-msg" v-if="error">
              <div class="col-xs-12">
                <p>{{ error }}</p>
              </div>
            </div>
            <div class="row middle-xs">
              <div class="col-xs-6 col-md-6 col-lg-5">
                <button class="btn" type="submit">{{ $t("loginPage.signIn") }}</button>
              </div>
              <div class="col-xs-6 col-md-6 col-lg-7">
                <label class="checkbox">{{ $t("loginPage.rememberMe") }}
                  <input type="checkbox">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </form>
          <p>{{ $t("loginPage.isForget") }} <router-link to="/recovery">{{ $t("loginPage.recover") }}</router-link></p>
          <p>{{ $t("loginPage.isNotRegister") }} <router-link to="/register">{{ $t("loginPage.createAcc") }}</router-link></p>
        </div>
        <div class="col-xs-12 col-md-6 text-left soc-btns">
          <p class="before">{{ $t("loginPage.signSoc") }}</p>

          <!-- <a href="#">
            <div class="soc-btn soc-btn-fb">
              <span>{{ $t("loginPage.fb") }}</span>
            </div>
          </a>

          <a href="#">
            <div class="soc-btn soc-btn-google">
              <span>{{ $t("loginPage.google") }}</span>
            </div>
          </a> -->

          <a @click="socialAuth('vk')">
            <div class="soc-btn soc-btn-vk">
              <span>{{ $t("loginPage.vk") }}</span>
            </div>
          </a>

          <a @click="socialAuth('google')">
            <div class="soc-btn soc-btn-google">
              <span>{{ $t("loginPage.google") }}</span>
            </div>
          </a>

          <!-- <a @click="socialAuth('fb')">
            <div class="soc-btn soc-btn-fb">
              <span>{{ $t("loginPage.fb") }}</span>
            </div>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '../lib/api'
import { fdToObj, saveToLS } from '../lib/utils'
import config from '../config'

export default {
  name: 'LoginPage',
  data: () => {
    return {
      error: "",
      recaptchaPublicKey: config.RECAPTCHA_PUBLIC
    }
  },
  methods: {
      async submitHandler(ev) {
        const form = ev.target
        const formObj = fdToObj(new FormData(form))

        this.error = ''

        const { data } = await login(formObj)

        if (data.status !== 1) {
          this.setErrorMsg(data.errorid)
          return;
        }

        saveToLS(config.LS_TOKEN_NAME, data.token) 
        form.reset()

        window.location.href = '/'
      },
      setErrorMsg(errorid) {
        let errorMsg = ''

        switch (errorid) {
          case 1:
            errorMsg = "Неправильный логин или пароль"
            break
          case 6:
            errorMsg = "Неверное значение каптчи"
            break
          case 7:
            errorMsg = "Необходимо подтвердить почту. Если письмо не приходит, то проверьте раздел 'Спам'"
            break;
          default:
            errorMsg = "Техническая ошибка. Попробуйте позже"
        }

        this.error = errorMsg
      },
      changeLocale(ev) {
        this.$i18n.locale = ev.target.value
      },
      socialAuth(provider) {
        const providerConfig = config['oauth'][provider]
        const q = providerConfig['q']

        const url = providerConfig['url'] + '?' + Object.keys(q).map(function (i) { return i + '=' + encodeURIComponent(q[i]) }).join('&')

        window.location.href = url
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  margin-left: 2px;
}

.soc-btns {
  cursor: pointer;

  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}
</style>
