<template>
    <div class="loader">
        <div>
            <img src="~@/assets/loader.gif" alt="">
            <div>Loading...</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'loader'
}
</script>

<style lang="scss" scoped>
    .loader {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: #788584;
        z-index: 99;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
</style>
