import axios from 'axios'
import config from '../config'
import { preparePassword } from './utils'

const req = axios.create({
    baseURL: config.API_URL,
    withCredentials: true
})

export const login = ({ login, password, 'g-recaptcha-response': captcha }) => {
    return req.post('/user/login', { login, captcha, password: preparePassword(password) })
}

export const register = ({ name, email, password, 'g-recaptcha-response': captcha }) => {
    return req.post('/user/register', { name, email, captcha, password: preparePassword(password) })
}

export const recovery = ({ email, 'g-recaptcha-response': captcha }) => {
    return req.post('/user/recovery', { email, captcha })
}

export const updatePass = ({ hash, password }) => {
    return req.post('/user/update-pass', { hash, password: preparePassword(password) })
}

export const checkToken = token => {
    return req.post('/user/verify', { token })
}

export const changePass = ({ token, password }) => {
    return req.post('/user/change-pass', { token, password: preparePassword(password) })
}

export const changeEmail = ({ token, email }) => {
    return req.post('/user/change-email', { token, email })
}

export const socialComplete = ({ hash, name }) => {
    return req.post('/user/social-complete', { hash, name })
}

export default req
