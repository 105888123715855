<template>
    <div></div>
</template>

<script>
import config from '../config'

export default {
  name: 'LogoutPage',
  data: () => {
    return {}
  },
  created() {
      window.localStorage.removeItem(config.LS_TOKEN_NAME)
      window.location.href = '/'
  }
}
</script>
