<template>
  <div class="row center-xs middle-xs register-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <div class="change-lang">
        <select name="">
          <option value="RU">RU</option>
          <option value="EN">EN</option>
        </select>
      </div>
      <h1>{{ $t("registerPage.title") }}</h1>
      <div class="success-msg" v-if="successResult">
        <p>Аккаунт успешно создан. На вашу почту отправлена ссылка для подтверждения аккаунта.</p>
      </div>
      <form @submit.prevent="submitHandler">
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <input class="input" type="email" name="email" :placeholder="this.$t('registerPage.email')" autocomplete="off" required>
          <input class="input" type="text" name="name" :placeholder="this.$t('registerPage.nick')" autocomplete="off" minlength="5" maxlength="60" required>
        </div>
        <div class="col-xs-12 col-md-6 text-left">
          <input class="input" type="password" name="password" v-model="password" :placeholder="this.$t('registerPage.pass')" minlength="8" required>
          <input class="input" type="password" name="confirm_password" v-model="confirmPassword" :placeholder="this.$t('registerPage.rptPass')" minlength="8" required>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <div class="row error-msg" v-if="error">
            <div class="col-xs-12">
              <p>{{ error }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <label class="checkbox">{{ $t("registerPage.agreeRules") }}
                <input type="checkbox" checked>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <label class="checkbox">{{ $t("registerPage.agreePromo") }}
                <input type="checkbox" checked>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div 
                class="g-recaptcha" 
                :data-sitekey="recaptchaPublicKey" 
                data-theme="dark"
                style="transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;" >Loading captcha...</div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <button class="btn full-width" type="submit">{{ $t("registerPage.continue") }}</button>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 text-left">
          <div class="soc-btns">
            <p>{{ $t("loginPage.signSoc") }}</p>

            <div>
              <!-- <a @click="socialAuth('fb')">
                <img src="@/assets/fb.png" alt="">
              </a> -->

              <a @click="socialAuth('google')" class="center">
                <img src="@/assets/google.png" alt="">
              </a>

              <a @click="socialAuth('vk')">
                <img src="@/assets/vk.png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import { register } from '../lib/api'
import { fdToObj } from '../lib/utils'
import config from '../config'

export default {
  name: 'RegisterPage',
  data() {
    return {
      password: "",
      confirmPassword: "",
      error: "",
      recaptchaPublicKey: config.RECAPTCHA_PUBLIC,
      successResult: false
    }
  },
  mounted() {
      try {
          window.grecaptcha.render(document.querySelector('.g-recaptcha'))
      } catch (err) {
          window.console.error(err)
      }
  },
  methods: {
    checkPasswords() {
      if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
        return true
      }
      
      return false
    },
    async submitHandler(ev) {
      const form = ev.target

      if (!this.checkPasswords()) {
        this.error = "Пароли не совпадают"
        return false
      }

      this.error = ''

      const formObj = fdToObj(new FormData(form))
      const { data } = await register(formObj)

      try {
        window.grecaptcha.reset()
      } catch (err) {
        window.console.error(err)
      }

      if (data.status !== 1) {
        this.setErrorMsg(data.errorid)
        return;
      }

      form.reset()
      this.successResult = true

      setTimeout(() => {
        this.successResult = false
      }, 5000);
    },
    setErrorMsg(errorid) {
      let errorMsg = ''

      switch (errorid) {
        case 2:
          errorMsg = "Пользователь с такой почтой или ником уже существует"
          break
        case 5:
          errorMsg = "Введенные данные некорректны"
          break
        case 6:
          errorMsg = "Неверное значение каптчи"
          break
        default:
          errorMsg = "Техническая ошибка. Попробуйте позже"
      }

      this.error = errorMsg
    },
    socialAuth(provider) {
      const providerConfig = config['oauth'][provider]
      const q = providerConfig['q']

      const url = providerConfig['url'] + '?' + Object.keys(q).map(function (i) { return i + '=' + encodeURIComponent(q[i]) }).join('&')

      window.location.href = url
    }
  }
}
</script>

<style scoped lang="scss">
.register-page {
  margin-top: 150px;
}

.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  margin-left: 2px;
}

label {
  margin-bottom: 10px;
}

.btn {
  margin-bottom: 10px;
}

.soc-btns {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;

  a {
    text-decoration: none;
    cursor: pointer;

    &.middle {
      margin: 0 10px;
    }
  }

  div {
    a {
      display: inline-block;
      margin: 0 10px;

      img {
        max-width: 40px;
      }
    }
  }
}
</style>
